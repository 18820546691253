import {React, Component } from 'react';
import { Breadcrumb, Layout, Menu, Space, theme } from 'antd';
import {isMobile} from 'react-device-detect';

import AOS from 'aos';

import {withGetScreen} from 'react-getscreen'

import ToolbarMenu from '../components/ToolbarMenu';
import FooterMenu from '../components/FooterMenu';
import Intro from '../components/Intro';
import Ido from '../components/Ido';
import Experience from '../components/Experience';
import IntroMobile from '../components/mobile/IntroMobile';
import IdoMobile from '../components/mobile/IdoMobile';
import ToolbarMenuMobile from '../components/mobile/ToolbarMenuMobile';
import ExperienceMobile from '../components/mobile/ExperienceMobile';
import MySkills from '../components/MySkills.js';
import MySkillsMobile from '../components/mobile/MySkillsMobile';
import MyProjects from '../components/MyProjects';
import MyProjectsMobile from '../components/mobile/MyProjectsMobile';
import MyReviews from '../components/MyReviews';
import MyReviewsMobile from '../components/mobile/MyReviewsMobile';
import FooterMenuMobile from '../components/mobile/FooterMenuMobile';

import 'aos/dist/aos.css'; // You can also use <link> for styles
const { Header, Content, Footer } = Layout;

class Portfolio extends Component{

    constructor(props){
        super(props);
    }
    componentDidMount() {
        AOS.init();
    }

    render(){
        console.log("ismobile",isMobile);
        return(
            <Layout className="layout" style={{backgroungColor:"#FFF"}}>
                {
                    (isMobile) ? (
                        <div>
                            {/* Mobile components */}
                            <ToolbarMenuMobile />
                            <IntroMobile />
                            <IdoMobile />
                            <ExperienceMobile />
                            <MySkillsMobile />
                            <MyProjectsMobile />
                            <MyReviewsMobile />
                            <FooterMenuMobile />
                        </div>
                    ) : (
                        <div>
                            {/* Desktop version */}
                            <ToolbarMenu />
                            <Intro />
                            <div style={{ height: 30 }}></div>
                            <Ido />
                            <div style={{ height: 30 }}></div>
                            <Experience />
                            <MySkills />
                            <MyProjects />
                            <MyReviews />
                            <FooterMenu />
                        </div>
                    )
                }

            </Layout>
        )
    }
}

export default Portfolio;