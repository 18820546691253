import {React, Component } from 'react';
import { Breadcrumb, Layout, Menu, Row, theme,Timeline } from 'antd';

import "../../css/mobile/ExperienceMobile.css";
import { experiences } from '../../data/Content';


class ExperienceMobile extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className='m-experience-container' id="experience">

                <div className='m-container-title' data-aos="fade-down">
                    <span>Where I’ve Worked</span>
                </div>

                <div className='m-experience-container-inside'>
                    <Timeline style={{ backgroundColor: "#E8F6FB" }}>
                        {
                            experiences.map((experience, index) => (
                                <Timeline.Item color={experience?.color}>
                                    <div className='m-exp-cont-item' style={{
                                        borderLeft: index % 2 == 0 ? "6px solid " + experience?.color : 0,
                                        borderRight: index % 2 == 1 ? "6px solid " + experience?.color : 0,
                                        marginRight: index % 2 == 1 ? "-6px" : 0,
                                    }} data-aos="fade-right">
                                        <div className='m-exp-cont-item-company'>{experience?.company}</div>
                                        <div className='m-exp-cont-item-date'>{experience?.date}</div>
                                        <div className='m-exp-cont-item-location'>({experience?.location})</div>
                                    </div>
                                </Timeline.Item>
                            ))}
                    </Timeline>
                </div>
            </div>
        )
    }
}

export default ExperienceMobile;