import {React, Component } from 'react';
import { Breadcrumb, Layout, Menu, Tag } from 'antd';
import { Col, Row } from 'antd';

import "./../../css/mobile/MySkillsMobile.css";
import { skills } from '../../data/Content';


class MySkillsMobile extends Component{

  constructor(props){
      super(props)
  }
  

  render(){
    return(
      <div className='m-my-skills-container' id="skill">

        <div className='m-container-title' data-aos="fade-down">
          <span>Skills I Have Mastered On</span>
        </div>

          {
            skills.map((item, index) => (
                  <Row className='m-myskill-items' style={{
                     borderLeft: index % 2 == 0 ? "6px solid" + item?.color_code : 0,
                     borderRight:  index % 2 == 1 ? "6px solid" + item?.color_code : 0,
                    }} data-aos="fade-up">
                    <Col span={3}>
                      <div className='m-myskill-icon-container' style={{ background: item?.color_code }}>
                        <i className={item?.icon}></i>
                      </div>
                    </Col>
                    <Col span={16} style={{ marginLeft: "16px" }}>
                      <div className='m-myskill-i-do-number' style={{ color: item?.color_code }}>{item?.title}</div>
                      <div className='m-myskill-i-do-txt' >{item?.total_proj}+ Projects </div>
                    </Col>

                    <Col style={{ marginTop: "10px" }}>
                      {
                        item?.skills.map((skills, skills_index) => (
                          <Tag color={item?.color_code} className='m-myskill-tag'>
                            {skills}
                          </Tag>
                        ))
                      }
                    </Col>
                  </Row>
            ))
          }
      </div>
    );
}}

export default MySkillsMobile;