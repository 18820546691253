import {React, Component } from 'react';
import { Col, Row ,Button,Popover} from 'antd';

import "./../../css/mobile/MyProjectsMobile.css";
import { projects } from '../../data/Content';

class MyProjectsMobile extends Component{

  constructor(props){
      super(props)
  }

  render(){
    return (
      <div className='m-my-project-container' id="project" style={{ justifyContent: "center", alignItems: "center" }}>
        <div className='m-container-title' data-aos="fade-down">
          <span>Some Things I’ve Built</span>
        </div>

        {
          projects.map((item, index) => (
            <div className='m-my-project-items' style={{ borderLeft: "6px solid " + item?.color, borderRight: "6px solid " + item?.color }} data-aos="fade-left">
              <Row>
                <Col span={17}>
                  <div className='m-my-project-name'>{item?.name}</div>
                  <div className='m-my-project-company'>{item?.company}</div>
                </Col>

                <Col span={5}>
                  <div className='m-my-project-icon-container' style={{ background: item?.color, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={item?.logo} style={{ height: 60, width: 60, borderRadius: 10 }} />
                  </div>
                </Col>
              </Row>
              <div className='m-my-project-position'>{item?.position}</div>
              <div className='m-my-project-body'>{item?.desc}</div>
              <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: 16 }}>
                <Popover placement="topRight" content={
                  <div>
                    {
                      item?.link?.web ? (
                        <div>
                          <Button className='m-my-project-visit-button'
                            onClick={() => { window.open(item?.link?.web, "_blank") }} >
                            Visit Website
                          </Button>
                        </div>
                      ) : null
                    }

                    {
                      item?.link?.android ? (
                        <div>
                          <Button className='m-my-project-visit-button'
                            onClick={() => { window.open(item?.link?.android, "_blank") }} >
                            Visit Android Store
                          </Button>
                        </div>
                      ) : null
                    }

                    {
                      item?.link?.ios ? (
                        <div>
                          <Button className='m-my-project-visit-button'
                            onClick={() => { window.open(item?.link?.ios, "_blank") }}>
                            Visit IOS Store
                          </Button>
                        </div>
                      ) : null
                    }
                    </div>
                } trigger="click">
                  <Button className='m-my-project-button'>See More</Button>
                </Popover>
              </div>
            </div>

          ))}
      </div>
    );
}}

export default MyProjectsMobile;
