import {React, Component } from 'react';
import { Breadcrumb, Layout, Menu, Tag } from 'antd';
import { Col, Row } from 'antd';

import "./../css/MySkills.css";
import { skills } from '../data/Content';

class MySkills extends Component{

  constructor(props){
      super(props)
  }
  

  render(){
    return(
      <div className='my-skills-container' id="skill">
        <div style={{ textAlign: "left" }} data-aos="fade-right">
          <span className='container-title' >Skills I Have Mastered On</span>
        </div>
        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center" ,marginTop:30}}>
          {
            skills.map((item, index) => (
              <Col span={7} style={{ margin: 10}} data-aos="fade-up">
                <div style={{ margin: "3px" }}>
                  <Row className='myskill-items' style={{ borderLeft: "6px solid" + item?.color_code, }}>
                    <Col span={3}>
                      <div className='myskill-icon-container' style={{ background: item?.color_code }}>
                        <i className={item?.icon}></i>
                      </div>
                    </Col>
                    <Col span={16} style={{ marginLeft: "16px" }}>
                      <div className='myskill-i-do-number' style={{ color: item?.color_code }}>{item?.title}</div>
                      <div className='myskill-i-do-txt' >{item?.total_proj}+ Projects </div>
                    </Col>

                    <Col style={{ marginTop: "10px" }}>
                      {
                        item?.skills.map((skills, skills_index) => (
                          <Tag color={item?.color_code} className='myskill-tag'>
                            {skills}
                          </Tag>
                        ))
                      }
                    </Col>
                  </Row>
                </div>
              </Col>
            ))
          }
        </Row>
      </div>
    );
}}

export default MySkills;