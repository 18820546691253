import {React, Component } from 'react';
import { Breadcrumb, Col, Layout, Menu, Row, Progress,Timeline } from 'antd';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import {experiences} from "./../data/Content.js";

import "./../css/Experience.css"

class Experience extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className='experience-container' id="experience">
                <div style={{ textAlign:"right"}}>
                    <span className='container-title'>Where I’ve Worked</span>
                </div>
                <Timeline style={{marginTop:50}} mode="right">
                    {
                        experiences.map((experience,index)=>(
                            <Timeline.Item color={experience?.color}
                            label={<div className='experience-desc-container' data-aos="fade-up-right">{experience?.desc}</div>}
                            > 
                                <div className='exp-cont-item' style={{ 
                                    borderLeft: index % 2 == 0 ? "6px solid "+experience?.color : 0,
                                    borderRight: index % 2 == 1 ? "6px solid "+experience?.color : 0, 
                                    marginRight: index % 2 == 1 ? "-6px" : 0, 
                                }} data-aos="fade-up-left">
                                    <Row>
                                        <Col span={20}>
                                            <div className='exp-cont-item-company'>{experience?.company}</div>
                                            <div className='exp-cont-item-date'>{experience?.date}</div>
                                            <div className='exp-cont-item-location'>({experience?.location})</div>
                                        </Col>
                                        <Col span={4} style={{ display:"flex",justifyContent:"flex-end"}}>
                                            <Progress type="circle" width={70} percent={experience?.percent} strokeColor={{ '0%': experience?.color, '100%': '#108ee9' }} style={{fontFamily:"medium"}}/>
                                        </Col>
                                    </Row>
                                   
                                </div>
                            </Timeline.Item>
                        ))
                    }
                </Timeline>
            </div>
        )
    }
}

export default Experience;