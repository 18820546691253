export const experiences = [
    {
        company:"Software Design Engineer II, FORTIVE (SPT)",
        date:"Nov 2022 - Present",
        location:"Elizabethtown, North Carolina, United States",
        color:"#286F6C",
        link:"https://www.specialtyproducttechnologies.com/",
        percent:5,
        desc: "Specialty Product Technologies (SPT, legal entity Dynapar) is a group of core technology companies based in Elizabethtown, NC consisting of 6 product brands. SPT designs, manufactures and markets innovative products, services and technologies to the industrial automation marketplace."
    },
    {
        company:"Software Design Engineer II, FORTIVE (TEKTRONIX)",
        date:"Jun 2021 - Nov 2022",
        location:"Bengaluru, Karnataka, India",
        color:"#FFB028",
        link:"https://www.tek.com/",
        percent:40,
        desc: "Tektronix offers a range of calibration and warranty extension plans for purchase with your instrument to protect and maintain its performance over the years."    
    },
    {
        company:"Mobile Application Developer, Stylelife (Alive)",
        date:"Nov 2020 - Jun 2021",
        location:"Bengaluru, Karnataka, India",
        color:"#BF1DC2",
        link:"https://iamalive.app/index.html",
        percent:20,
        desc: "Alive is built for everyone to plan their aspirations, big or small. Whether you plan to go to the best pub in your city or whether you plan to go for an outstation trip, or whether you want to buy a premium product. You let your plans known on Alive and we will convert your plans into reality, bringing you absolute delight!."
    },
    {
        company:"Software Design Engineer II, Digiretail (MyDukan)",
        date:"Apr 2019 - Aug 2020",
        location:"Bengaluru, Karnataka, India",
        color:"#FF31C5",
        link:"https://mydukanretailer.com/",
        percent:30,
        desc: "Digiretail is B2B data platform empowering retailers with relevant information to nurture their growth. With active market trends,B2B trade, inventory management solution & brand engagement, Digiretail fosters appropriate technology to ease thier life and scale business."    
    },
    {
        company:"Android Application Developer Intern, (Kloh)",
        date:"Jun 2018 - Aug 2018",
        location:"Bengaluru, Karnataka, India",
        color:"#009ED0",
        link:"",
        percent:5,
        desc: "Kloh is an online event booking software where people can book events for their weekends and enjoy.Users can make a group like social media and book event tickets in bulk."
    },
    
];

export const projects = [
  {
    name: "TracMile",
    company: "Dynaper Coperation",
    position: "Software Design Engineer II",
    year: "Nov 2020 – Present",
    desc: "TracMile is the leading mileage tracking app designed to simplify and optimize your mileage tracking experience.",
    logo: "https://play-lh.googleusercontent.com/XTGfrj5-7v5thOEk0-En8UHmDDdteyRZpojoB2pQ79Z-cW6sUM5EhLB5pn28mq_2W2-b=w480-h960-rw",
    color: "#2B60A5",
    link: {
      android: "https://play.google.com/store/apps/details?id=com.hermesprototype",
      ios: "https://apps.apple.com/us/app/tracmile/id1620482004",
      web: ""
    }
  },
  {
    name: "Alive",
    company: "Stylelife Technologies Ptv.",
    position: "Senior Mobile Application Engineer (Android & IOS)",
    year: "Nov 2020 – June 2021",
    desc: "Alive is created for us to be more social, to have more fun, to spend more time with our friends.",
    logo: "https://play-lh.googleusercontent.com/bHCMzLniUoSlUDtUZtGqzKdOg1IGTkjd0TX29HLe6Gzlxo0G_XhOkg9aV9oHF0BUpaQ=s360-rw",
    color: "#DC706F",
    link: {
      android: "https://iamalive.app/index.html",
      ios: "",
      web: "https://iamalive.app/index.html"
    },
  },
  {
    name: "MyDukan",
    company: "Digiretail Software Ptv.",
    position: "Mobile Application Engineer (Android)",
    year: "July 2019 – July 2020",
    desc: "Digiretail provides technology first solutions to SMBs for efficient supply-chain management by making them market aware with data trends.",
    logo: "https://lh3.googleusercontent.com/ObQkr9RqkijQO7N49XY7wJRxrVRq9yD25nov-Rgjly_f8DWL_WBUbFdYMO5eaC22ayNE=s360-rw",
    color: "#F5D448",
    link: {
      android: "https://bit.ly/mydukkan",
      ios: "",
      web: "https://mydukanretailer.com/",
    },
  },
  {
    name: "Deal Nepal",
    company: "Personal Project",
    position: "Founder and developer",
    year: "July 2021 – Current",
    desc: "Deal Nepal is the perfect online marketplace to buy and sell locally! No need to visit the flea market to find the best deals on pre-owned items! ",
    logo: "https://play-lh.googleusercontent.com/ebY1g89cHwZQ0RSgK4p13Q8oUzAuXO94vWQgut9x16AxQQR1Ajg1FA7MEtFfVlFCihQ=s360-rw",
    color: "#D93966",
    link: {
      android: "https://play.google.com/store/apps/details?id=com.dealnepal",
      ios: "",
      web: ""
    },
  },
  {
    name: "Smartshaalaa",
    company: "Personal Project",
    position: "Founder & Developer",
    year: "July 2020 – Present",
    desc: "Smartshaalaa helps all retailers in managing (inventory management, invoicing, billing, storing them in the cloud for free) and helps to grow their business faster.",
    logo: "https://lh3.googleusercontent.com/eoT0s_3p-nX5Kco4IXf2ri9gJcnMyUPfo7fcpA6hS7mUf-uZxBvEE-1_UKWDxsYIEsgV=s360-rw",
    color: "#295FB6",
    link: {
      android: "https://bit.ly/smartshaalaa",
      ios: "",
      web: "https://smartshaalaa.com"
    },
  },
  {
    name: "Kloh",
    company: "Kloh Software Ptv",
    position: "Software Engineer Intern",
    year: "Jun 2018 – Aug 2018",
    desc: "KLOH is the easiest way to learn a new hobby class near you. From Salsa to Horse-Riding, from Swimming to Pottery, you can learn anything on Kloh.",
    logo: "https://lh3.googleusercontent.com/4ABE8NjsPTdVMH5wc5-bdT_7IppgT6_6DyVUwiVMFcF2YUAUzUEVZxO60FV2QFaWSuU=s360-rw",
    color: "#63C2A6",
    link: {
      android: "https://play.google.com/store/apps/details?id=com.dealnepal",
      ios: "",
      web: ""
    },
  },
  {
    name: "Voice News",
    company: "Personal Project",
    position: "Creator (Developer)",
    year: "2018 - Present",
    desc: "Voice News is an android application that helps the peoples to surf the news in different way than the other application.",
    logo: "https://lh3.googleusercontent.com/0oLpzUXLY85MabiDuKq_OSYDOnkHYr7KBzhM8zQmUZ-SWSpXCk4Kbe03Lo1TPGM2oq8=s360-rw",
    color: "#66D970",
    link: {
      android: "https://bit.ly/voice-news",
      ios: "",
      web: ""
    },
  },
  {
    name: "My Outfits",
    company: "Personal Projects",
    position: "Creator (Developer)",
    year: "Nov 2020 – June 2021",
    desc: "My Outfits app is an platform where user can make their fashion collection and save it online in different categories.",
    logo: "https://lh3.googleusercontent.com/SSmb9Xrbb2VCMwsgCVv5UupOindB00gFC9uFX9siUqyE-pBdpG0ujSZlE0ZhQDkL39o=s360-rw",
    color: "#71A8DF",
    link: {
      android: "https://bit.ly/myoutfitsa",
      ios: "",
      web: ""
    },
  },
];

export const skills = [
    {
      title: "Mobile App Development",
      total_proj: 20,
      skills: [
        "React Native (Android & IOS)",
        "Javascript",
        "Android studio",
        "Kotlin",
        "MVP Framework",
        "Java",
        "Flutter (Android & IOS)",
        "Xcode",
        "Google Store & Apple Connect Distrubation",
        "Push Notification", "Dynamic Linking",
        "Stand Alone Apps"
      ],
      color_code:"#009ED0",
      icon:"fas fa-mobile"
    },
    {
      title: "Web Development",
      total_proj: 15,
      skills: [
        "React Js",
        "Html",
        "CSS",
        "Jquery",
        "Javascript",
        "Php & Mysql",
        "Node Js",
        "MongoDB",
        "Ant Design",
        "Materials UI","Restful API","Spring Boot","NGINX",
        "Docker, Xampp","MQTT Service","CI/CD Deployment",
        "Hosting Godaddy & AWS"
      ],
      color_code:"#FFB028",
      icon:"fab fa-chrome"
    },
    {
      title: "Cloud Services",
      total_proj: 18,
      skills: [
        "AWS services","EC2 Server","SNS, SES","Code commit", "Lamda",
        "Elastic Beanstack" ,"Authentication", "Analytics", "Amplify", 
        "Route 53",
        "Firebase Services", "Realtime DB", "Cloud Messaging", "App Distrubation", "Dynamic Link",
        "MQTT Services",
      ],
      color_code:"#FF31C5",
      icon:"fab fa-sellsy"
    },
    {
      title: "UI/UX Designs",
      total_proj: 18,
      skills: [
        "Adobe XD","Figma","Wireframing","UI Prototyping","Dribble", "Pintrest",
        "Zaplin" ,"Design Guidelinees",
      ],
      color_code:"#BF1DC2",
      icon:"fas fa-drafting-compass"
    },{
      title: "Others Tools & technology",
      total_proj: 18,
      skills: [
        "Project Lead","Wocommerse & wordpress","Git","Postman", "MAMP",
        "Trello", "Jira", "Amplify","Agile Software development",
      ],
      color_code:"#286F6C",
      icon:"fab fa-google-wallet"
    },
  ];

  export let softwareSkills = [
    {
      skillName: "java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "React Native",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "MYSQL",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "php",
      fontAwesomeClassname: "fab fa-php"
    },
    {
      skillName: "Figma",
      fontAwesomeClassname: "fab fa-figma"
    },
    {
      skillName: "XD",
      fontAwesomeClassname: "fab fa-adobe"
    }
  ];

export const reviews = [{
  name: "Prabin Karki",
  linkedIn: "https://www.linkedin.com/in/prabin-karki-936546159/",
  review: "Milan is a software engineer and is incredibly skilled in coding and problem-solving. He has always willing to help and explain complex concepts in a way that is easy to understand. He is a valuable asset to any team and I would highly recommend him for any software development project as i have worked with him previously.",
  role: "Lead software engineer",
  company: " ArBa Development Studios",
  color: "#009ED0",
  image: "https://media.licdn.com/dms/image/C5603AQG0zhd19kcbwQ/profile-displayphoto-shrink_400_400/0/1518077981443?e=1708560000&v=beta&t=bchlrtKLjhmBeYyft56T8oUFR2VjIQc9U_5gFvajmHg"
}, {
  name: "Roshan Tajpuriya",
  linkedIn: "https://www.linkedin.com/in/roshan-tajpuriya/",
  review: "Milan is an outstanding Software Engineer, consistently producing high-quality work and meeting deadlines. He is a valuable team player and his contributions have greatly contributed to our team's success. I have a pleasure to work with him and I highly recommend them for any project.",
  role: "Software Engineer",
  company: "Scientific Games Lottery India Pvt. Ltd.",
  color: "#FFB028",
  image: "https://media.licdn.com/dms/image/C5103AQEOjjxgv6BGKA/profile-displayphoto-shrink_400_400/0/1534091757610?e=1708560000&v=beta&t=dABQaqtgz53txEXYy6lweHmIdRGMR0tEOphss-V9a9s",
}, {
  name: "Krishna Rajbanshi ",
  linkedIn: "https://www.linkedin.com/in/krishna-rajbanshi-75a378128/",
  review: "Milan is a software engineer and is incredibly skilled in coding and problem-solving. He has always willing to help and explain complex concepts in a way that is easy to understand. He is a valuable asset to any team and I would highly recommend him for any software development project as i have worked with him previously.",
  role: "Software engineer",
  company: "MEEM Memory Ltd",
  color: "#FF31C5",
  image: "https://media.licdn.com/dms/image/C5603AQG055bFznFyGg/profile-displayphoto-shrink_400_400/0/1517056702483?e=1708560000&v=beta&t=fSjv7hzYgJyHHc1DZyhRyGCDmB9f1ufmWutU4payuBs"
}, {
  name: "Parkash Rayamaji",
  linkedIn: "https://www.linkedin.com/in/prakash-rayamajhi-8970b212b/",
  review: "Milan Shrestha is an exceptional software engineer, his work is outstanding, and his knowledge and skills in coding are truly impressive. He is dedicated to his work and always goes above and beyond to deliver the best possible results. His is a valuable asset to any team and I am lucky to have his as a friend.",
  role: "Software engineer",
  company: "ArBaDev Development Studio",
  color: "#BF1DC2",
  image: "https://media.licdn.com/dms/image/C4D03AQHmclFVwenDBg/profile-displayphoto-shrink_400_400/0/1656809115535?e=1708560000&v=beta&t=YWI3J3K3lXx11FkPxskITS7I2RARLF0cLzs1139aUx0"
}, {
  name: "Julius Daimary",
  linkedIn: "https://www.linkedin.com/in/julius-daimary-349128203/",
  review: "Milan shrestha is a highly skilled software engineer, his work is always top-notch and his coding skills are truly impressive. He is a great problem solver and are always willing to go the extra mile to deliver the best results.  He is a valuable asset to any team and I would highly recommend him for any software development project.",
  role: "Software engineer",
  company: "Meem memory development studio",
  color: "#286F6C",
  image: "https://media.licdn.com/dms/image/C4D03AQHQFWCRwhEBnw/profile-displayphoto-shrink_400_400/0/1624870113890?e=1708560000&v=beta&t=Xisq6Jzi5Z-8nNFdz_JLlrKnr6wP3TqggscvZ2eQDtI"
}];