import {React, Component } from 'react';
import { Col, Row ,Button, Popover, Divider} from 'antd';

import "./../css/MyProjects.css";
import { projects } from '../data/Content';


class MyProjects extends Component{

  constructor(props){
      super(props)
  }
  

  render(){
    return(
      <div className='my-project-container' id="project">
        <div style={{ textAlign: "right" }} data-aos="fade-left">
          <span className='container-title'>Some Things I’ve Built</span>
        </div>
         <Row style={{justifyContent:"center",alignItems:"center",}}>
          {
            projects.map((item,index)=>(
                <Col span={8}  data-aos="fade-up" style={{display:'flex',justifyContent:"center",alignItems:"center",}}>
                  
          <div className='my-project-items' style={{ borderLeft: "6px solid "+item?.color, borderRight: "6px solid "+item?.color }}>
            <Row>
              <Col span={17}>
                <div className='my-project-name'>{item?.name}</div>
                <div className='my-project-company'>{item?.company}</div>
              </Col>

              <Col span={5}>
                <div className='my-project-icon-container' style={{ background: item?.color,display:"flex",justifyContent:"center",alignItems:"center" }}>
                  <img src={item?.logo} style={{height:60,width:60,borderRadius:10}} />
                </div>
              </Col>
            </Row>
            <div className='my-project-position'>{item?.position}</div>
            <div className='my-project-body'>{item?.desc}</div>
            <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: 16 }}>
                <Popover placement="topRight" content={
                  <div>
                        {
                          item?.link?.web ? (
                            <div>
                              <Button className='my-project-visit-button' 
                                onClick={() => { window.open(item?.link?.web, "_blank") }}>
                                  Visit Website
                              </Button>
                            </div>
                          ) : null
                        }

                        {
                          item?.link?.android ? (
                            <div>
                              <Button className='my-project-visit-button' 
                                onClick={() => { window.open(item?.link?.android, "_blank") }}>
                                  Visit Android Store
                              </Button>
                            </div>
                          ) : null
                        }

                        {
                          item?.link?.ios ? (
                            <div>
                              <Button className='my-project-visit-button' 
                                onClick={() => { window.open(item?.link?.ios, "_blank") }}>
                                  Visit IOS Store
                              </Button>
                            </div>
                          ) : null
                        }
                        
                  </div>
                } trigger="click">
                  <Button className='my-project-button'>See More</Button>
                </Popover>
            </div>
          </div>

          </Col>
          
          ))}

</Row>
    </div>
      
    );
}}

export default MyProjects;
