import {React, Component } from 'react';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { TypeAnimation } from 'react-type-animation';

import "./../css/Intro.css"

const { Header, Content, Footer } = Layout;

class Intro extends Component{
    constructor(props){
        super(props);
        this.state = {
            t1Status:false,
            t2Status:false,
            t3Status:false,
        };
    }


    render(){
        return(
            <Content className='intro-container'>
                <div className='titleContainer' id="intorduction" >
                    
                    <TypeAnimation
                        sequence={['Hi,', 1000, () => { this.setState({ t2: true }); }]}
                        wrapper="div"
                        cursor={false}
                        speed={70}
                        className='titleText'
                    />

                    {
                        this.state.t2 ? (
                            <TypeAnimation
                                sequence={['I`m Milan,', 1000, () => { this.setState({ t3: true }); }]}
                                wrapper="div"
                                cursor={false}
                                speed={70}
                                className='titleText'
                            />
                        ) : null
                    }

                    {
                        this.state.t3 ? (
                            <TypeAnimation
                                sequence={['Software Engineer', 1000, () => { this.setState({ t1: true }); }]}
                                wrapper="div"
                                cursor={false}
                                speed={70}
                                className='titleText'
                            />
                        ) : null
                    }
                
                   {/* <div className='titleText'>Hi,</div>
                   <div className='titleText'>I`m Milan,</div>
                   <div className='titleText'>Software Engineer</div> */}
                </div>
                <div className='image-container'>
                    <div className="leftIntro">
                        <div className='noOfExp'>5+</div>
                        <div className='rightExpContainer'>
                            <div className='yearsTitle'>Years</div>
                            <div className='yearsTitle'>Experiences</div>
                        </div>
                    </div>
                    <div className="rightIntro" data-aos="fade-down">
                      <div className='personal-image_bg'>
                        <img src={require("./../assets/image/milan.jpeg")} className="profile-image"  data-aos="zoom-in"/>
                      </div>
                    </div>
                </div>
            </Content>
        )
    }
}

export default Intro;