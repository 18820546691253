import {React, Component } from 'react';
import { Breadcrumb, Col, Divider, Layout, Menu, Row, theme } from 'antd';

import "./../css/FooterMenu.css";
import SocialMedia from './SocialMedia';

const { Header, Content, Footer } = Layout;


const menuList = ["Intro","Experience","Skill","Projects","Talks"];

class FooterMenu extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className='my-footer-container' id="footer">
                <div className='footer-menu-message'>Let`s make togther <br/> something amazing.</div>
                <div style={{height:100}}></div>
                <Row>
                    <Col span={12} style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                        <div className='footer-menu-message' style={{fontSize:"2.0em"}}>Start by <span style={{color:"#FFB028"}}>saying Hi.</span></div>
                    </Col>
                    <Col span={12} style={{display:"flex",justifyContent:"flex-end"}}>
                        <div className='footer-menu-social-container'>
                            <SocialMedia />
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={12} style={{display:"flex",justifyContent:"flex-start"}}>
                        <div className='footer-menu-text'> <span style={{fontSize:"1.4em"}}>MILAN</span> | @copyright reserver 2022.</div>
                    </Col>
                    <Col span={12} style={{display:"flex",justifyContent:"flex-end"}}>
                        <div className='footer-menu-text'> Design & Develop by milan shrestha </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default FooterMenu;