import {React, Component } from 'react';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import "./../css/ToolbarMenu.css"

const { Header, Content, Footer } = Layout;


const menuList = ["INTRO","EXPERIENCE","SKILL","PROJECTS","TALKS", "+1 301 234 4055"];

class ToolbarMenu extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <Header className='toolbar' style={{backgroundColor:"#E8F6FB"}}>
                <div className='menu'>
                    <div className='menuItem'>
                        <a href="#introduction" className="menuItemTxt">INTRO</a>
                    </div>

                    <div className='menuItem' style={{marginLeft:24,marginRight:24}}>
                        <a href="#ido" className="menuItemTxt">WHAT I DO</a>
                    </div>

                    <div className='menuItem'>
                        <a href="#experience" className="menuItemTxt">EXPERIENCE</a>
                    </div>

                    <div className='menuItem'>
                        <a href="#skill" className="menuItemTxt">SKILL</a>
                    </div>

                    <div className='menuItem'>
                        <a href="#project" className="menuItemTxt">PROJECTS</a>
                    </div>

                    <div className='menuItem'>
                        <a href="#review" className="menuItemTxt">REVIEWS</a>
                    </div>

                    <div className='menuItem'>
                        <a href="#footer" className="menuItemTxt">TALKS</a>
                    </div>

                    <div className='menuItemResume'>
                        <div className='menuItemResumeBtn' onClick={()=>{ window.open("https://drive.google.com/file/d/1rrQUy2L4fWa2WMRWTVjJv342WPRzfEin/view?usp=sharing", "_blank") }}>
                            <a href="#" className="menuItemTxt">MY RESUME</a>
                        </div>
                    </div>

                    <a href="tel:+1 380 218 5985" className='menuItem-a'>
                        <div className='menuItem' style={{ marginLeft: 50, width: 150 }}>
                            <div className="menuItemTxt" style={{ color: "#0D2F3F", fontFamily: "bold" }}>+1 (301) 234-4055</div>
                        </div>

                        <div className='menuItemCallIcon'>
                            <i className="fas fa-phone"></i>
                        </div>
                    </a>
                </div>
                
            </Header>
        )
    }
}

export default ToolbarMenu;