import {React, Component } from 'react';
import { Breadcrumb, Divider, Layout, Menu, Tag } from 'antd';
import { Col, Row } from 'antd';

import "./../css/MyReviews.css";
import { reviews } from '../data/Content';

class MyReviews extends Component{

  constructor(props){
      super(props)
  }
  
  render(){
    return(
      <div className='my-reviews-container' id="review">
        <div style={{ textAlign: "left" }} data-aos="fade-right">
          <span className='container-title'>Peoples Talk`s About Me</span>
        </div>

        <Row style={{justifyContent:"center",alignItems:"center",marginTop:30}}>
          {
            reviews.map((item,index)=>(
                <Col span={8} data-aos="fade-up" onClick={()=>{window.open(item?.linkedIn, "_blank")}} style={{display:'flex',justifyContent:"center",alignItems:"center",}}>
            <div className='my-reviews-items' style={{ borderLeft: "6px solid "+item?.color ,borderRight: "6px solid "+item?.color }}>
              <div style={{display:"flex",justifyContent:"center",alignItems: "center",height:70}}>
                <div className='my-reviews-picture' style={{ background: "#009ED0" }}>
                  <img src={item?.image} style={{width:100,heigh:100,borderRadius:50}}/>
                </div>
              </div>
                <div className='my-reviews-text'>{item?.review}</div>
                <Divider />
                <div className='my-reviews-name' style={{color:item?.color,marginBottom:5}}>{item?.name}</div>
                <div className='my-reviews-position'>{item?.role}</div>
                <div className='my-reviews-at'>at</div>
                <div className='my-reviews-company'>{item?.company} </div>
            </div>
            </Col>
            ))}
            </Row>
      </div>
    );
}}

export default MyReviews;