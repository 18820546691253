import {React, Component } from 'react';
import { Breadcrumb, Col, Divider, Layout, Menu, Row, theme } from 'antd';

import "./../../css/mobile/FooterMenuMobile.css";
import SocialMedia from '../../components/SocialMedia';

const { Header, Content, Footer } = Layout;
const menuList = ["Intro","Experience","Skill","Projects","Talks"];

class FooterMenuMobile extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className='m-my-footer-container'>
                <div className='m-footer-menu-message'>Let`s make togther <br/> something amazing.</div>
                <div style={{height:50}}></div>
                <div className='m-footer-menu-message' style={{fontSize:"1.4em",textAlign:"center"}}>Start by <span style={{color:"#FFB028"}}>saying Hi.</span></div>
                <div style={{ display: "flex", justifyContent:"center"}}>
                    <div className='m-footer-menu-social-container'>
                        <SocialMedia />
                    </div>
                </div>

                <div className='m-footer-menu-text'> Design & Develop by Milan Shrestha </div>
                <Divider />
                <div className='m-footer-menu-text'> <span style={{fontSize:"1.0em"}}>MILAN</span> | @copyright reserver 2022.</div>
                    
            </div>
        )
    }
}

export default FooterMenuMobile;