import logo from './logo.svg';
import './App.css';

// import 'swiper/css/swiper.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import "./app/assets/fonts/Jost-Black.ttf";
import "./app/assets/fonts/Jost-Bold.ttf";
import "./app/assets/fonts/Jost-ExtraBold.ttf";
import "./app/assets/fonts/Jost-Medium.ttf";
import "./app/assets/fonts/Jost-Light.ttf";

import Portfolio from './app/pages/Portfolio';


function App() {
    return <Portfolio />;
}

export default App;
