import {React, Component } from 'react';
import { Layout,Drawer} from 'antd';
import { MenuFoldOutlined   } from '@ant-design/icons';

import "../../css/mobile/ToolbarMenuMobile.css";
import SocialMedia from "./../SocialMedia";

const menuList = ["INTRO","EXPERIENCE","SKILL","PROJECTS","TALKS", "+1 301 234 4055"];

class ToolbarMenuMobile extends Component{
    constructor(props){
        super(props);
        this.state ={
            drawer: false
        }
    }

    render(){
        return(
            <div style={{backgroundColor:"#E8F6FB"}}>

                <div className='m-menu'>
                    <div style={{ width: "20%" }}></div>
                    <div className='m-logo-container'>
                        <a href="" className="m-logo">
                            <span className="m-logo-name">Milan Shrestha</span>
                        </a>
                    </div>
                    <div style={{  width: "20%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <MenuFoldOutlined style={{ fontSize: '20px', color: '#286F6C' }} onClick={()=>{this.setState({drawer: true})}} />
                    </div>
                </div>

                <Drawer
                    title={
                        <div className='m-menu'>
                            <div style={{ width: "20%" }}></div>
                            <div className='m-logo-container'>
                                <a href="" className="m-logo">
                                    <span className="m-logo-name">Milan Shrestha</span>
                                </a>
                            </div>
                            <div style={{ width: "20%" }}></div>
                        </div>
                    }
                    footer={
                        <SocialMedia />
                    }
                    placement={"right"}
                    closable={false}
                    width="70%"
                    onClose={() => { this.setState({ drawer: false }) }}
                    open={this.state.drawer}>

                    <div className='m-menuItem'>
                        <a href="#introduction" onClick={()=>{this.setState({drawer: false})}} className="m-menuItemTxt">INTRO</a>
                    </div>

                    <div className='m-menuItem'>
                        <a href="#ido" onClick={()=>{this.setState({drawer: false})}} className="m-menuItemTxt">WHAT I DO</a>
                    </div>

                    <div className='m-menuItem'>
                        <a href="#experience" onClick={()=>{this.setState({drawer: false})}} className="m-menuItemTxt">EXPERIENCE</a>
                    </div>

                    <div className='m-menuItem'>
                        <a href="#skill" onClick={()=>{this.setState({drawer: false})}}  className="m-menuItemTxt">SKILL</a>
                    </div>

                    <div className='m-menuItem'>
                        <a href="#project" onClick={()=>{this.setState({drawer: false})}}  className="m-menuItemTxt">PROJECTS</a>
                    </div>

                    <div className='m-menuItem'>
                        <a href="#review" onClick={()=>{this.setState({drawer: false})}}  className="m-menuItemTxt">REVIEWS</a>
                    </div>

                    <div className='m-menuItem'>
                        <a href="#footer" onClick={()=>{this.setState({drawer: false})}}  className="m-menuItemTxt">TALKS</a>
                    </div>

                    <a href="tel:+1 380 218 5985">
                    <div className='m-phone-div'>
                        <div className='m-menuItem m-menuItem-phone' >
                            <span className="m-menuItemTxt" style={{ color: "#0D2F3F", fontFamily: "bold" }}>+1 (301) 234-4055</span>
                        </div>

                        <div className='m-menuItemCallIcon'>
                        	<i className="fas fa-phone"></i>
                        </div>
                    </div>
                    </a>

<br/>
<br/>
                    <div className='m-menuItemResume'>
                        <div className='m-menuItemResumeBtn' onClick={()=>{ window.open("https://drive.google.com/file/d/1rrQUy2L4fWa2WMRWTVjJv342WPRzfEin/view?usp=sharing", "_blank") }}>
                            <a href="#" className="m-menuItemTxt">MY RESUME</a>
                        </div>
                    </div>

                </Drawer>
                    
                
                
            </div>
        )
    }
}

export default ToolbarMenuMobile;