import {React, Component } from 'react';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { TypeAnimation } from 'react-type-animation';

import "../../css/mobile/IntroMobile.css"
import SocialMedia from '../../components/SocialMedia';

const { Header, Content, Footer } = Layout;

class IntroMobile extends Component{
    constructor(props){
        super(props);
        this.state = {
            t1Status:false,
            t2Status:false,
            t3Status:false,
        };
    }

    render(){
        return(
            <Content className='m-intro-container'>
                <div className='m-titleContainer' id="intorduction">
                    <TypeAnimation
                        sequence={['Hi,', 1000, () => { this.setState({ t2: true }); }]}
                        wrapper="div"
                        cursor={false}
                        speed={70}
                        className='m-titleText'
                    />

                    {
                        this.state.t2 ? (
                            <TypeAnimation
                                sequence={['I`m Milan,', 1000, () => { this.setState({ t3: true }); }]}
                                wrapper="div"
                                cursor={false}
                                speed={70}
                                className='m-titleText'
                            />
                        ) : null
                    }

                    {
                        this.state.t3 ? (
                            <TypeAnimation
                                sequence={['Software Engineer', 1000, () => { this.setState({ t1: true }); }]}
                                wrapper="div"
                                cursor={false}
                                speed={70}
                                className='m-titleText'
                            />
                        ) : null
                    }

                   {/* <div className='m-titleText'>Hi,</div>
                   <div className='m-titleText'>I`m Milan,</div>
                   <div className='m-titleText'>Software Engineer</div> */}
                   {/* <div className='m-yearsTitle'>Hi there, I'm Milan Shrestha 👋 <br/>A passionate Software Design Engineer 🚀 having an experience of building (Mobile, Web & PC) software with (Java & Kotlin), JavaScript, Reactjs, Nodejs, React Native, MYSQL, MongoDB, AWS services and some other cool libraries and frameworks.</div> */}
            
                </div>
                

                <div className='m-image-container'>
                    <div className="m-rightIntro">
                      <div className='m-personal-image_bg'>
                        <img src={require("../../assets/image/milan.jpeg")} className="m-profile-image" />
                      </div>
                    </div>
                </div>

                
                <div className="m-leftIntro">
                    <div className='m-noOfExp'>5+ </div>
                    <div className='m-rightExpContainer'>
                        <div className='m-yearsTitle'>Years</div>
                        <div className='m-yearsTitle'>Experiences</div>
                    </div>
                </div>

                {/* <div style={{ display: "flex", justifyContent:"center"}}>
                    <div className='m-footer-menu-social-container'>
                        <SocialMedia />
                    </div>
                </div> */}

            </Content>
        )
    }
}

export default IntroMobile;