import {React, Component } from 'react';
import { Breadcrumb, Col, Layout, Menu, Row, theme } from 'antd';
import { motion } from "framer-motion";

import "./../css/Ido.css"
import SoftwareSkill from './SoftwareSkill';

const { Header, Content, Footer } = Layout;
export const idoSkills = [
    {
      title: "Mobile App Development",
      total_proj: 8,
      color_code:"#009ED0",
      icon:"fas fa-mobile"
    },
    {
        title: "Back-End Development",
        total_proj: 11,
        color_code:"#6aa84f",
        icon:"fas fa-sitemap"
      },
    {
      title: "Web Development",
      total_proj: 8,
      color_code:"#FFB028",
      icon:"fab fa-chrome"
    },
    {
      title: "Cloud Services",
      total_proj: 6,
      color_code:"#FF31C5",
      icon:"fab fa-sellsy"
    },
    {
      title: "UI/UX Designs",
      total_proj: 13,
      color_code:"#BF1DC2",
      icon:"fas fa-drafting-compass"
    },
  ];

class Ido extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <Row className='ido-container' id='ido'>
                <Col span={12} className="left-ido">
                    <div className='titleText' data-aos="flip-down">What I do?</div>
                    <div data-aos="zoom-in">
                        <ol>

                            <li className='m-li-txt'>Cross-Platform Expertise:
                            
                                <ul>
                                    <li className='sub-text'> - Proficient in developing applications that seamlessly run on multiple platforms, including IOS, Android, Windows, macOS, and Linux.</li>
                                    <li className='sub-text'> - Skilled in cross-platform frameworks such as React Native and Flutter.</li>
                                </ul>
                            </li><br/>

                            <li className='m-li-txt'>Full Stack Web Development:
                                <ul>
                                    <li className='sub-text'> - Extensive experience in full stack development, covering both frontend and backend technologies.</li>
                                    <li className='sub-text'> - Proficient in modern frontend frameworks such as React.js, Html, Css, jQuery, AJAX, Next.js . </li>
                                </ul>
                            </li><br/>

                            <li className='m-li-txt'> Backend Technologies:
                                <ul>
                                    <li className='sub-text'> - Strong backend development skills using technologies like Node.js, Java Springboot. </li>
                                    <li className='sub-text'> - Database design and management expertise with SQL (e.g., MySQL, PostgreSQL) and NoSQL (e.g., MongoDB) databases.</li>
                                </ul>
                            </li><br/>

                            <li className='m-li-txt'> API Development:
                                <ul>
                                    <li className='sub-text'> - Designing, building, and maintaining robust RESTful APIs. </li>
                                    <li className='sub-text'> - Familiarity with GraphQL for efficient data retrieval and manipulation.</li>
                                </ul>
                            </li><br/>

                            <li className='m-li-txt'> DevOps Integration:
                                <ul>
                                    <li className='sub-text'> - Implementing DevOps practices for continuous integration and deployment (CI/CD) using tools like Jenkins, Docker .</li>
                                    <li className='sub-text'> - Ensuring the scalability, reliability, and security of deployed applications.</li>
                                </ul>
                            </li><br/>

                            <li className='m-li-txt'> Cloud Services:
                                <ul>
                                    <li className='sub-text'> - Experience with cloud platforms such as AWS, Azure, or Google Cloud.</li>
                                    <li className='sub-text'> - Deploying and managing applications in cloud environments for optimal performance.</li>
                                </ul>
                            </li><br/>

                            <li className='m-li-txt'> Version Control:
                                <ul>
                                    <li className='sub-text'> - Proficient in version control systems, particularly Git, for efficient collaboration and code management.</li>
                                </ul>
                            </li><br/>

                            <li className='m-li-txt'> Testing and Debugging:
                                <ul>
                                    <li className='sub-text'> - Implementing thorough testing strategies, including unit testing, integration testing, and end-to-end testing.</li>
                                    <li className='sub-text'> - Proficient in debugging and optimizing code for performance.</li>
                                </ul>
                            </li><br/>

                            <li className='m-li-txt'> Agile Methodology:
                                <ul>
                                    <li className='sub-text'>  - Working in Agile development environments, participating in sprints, and ensuring timely delivery of high-quality software.</li>
                                </ul>
                            </li><br/>

                            <li className='m-li-txt'> Collaboration and Communication:
                                <ul>
                                    <li className='sub-text'> - Effective communication and collaboration skills, working closely with cross-functional teams.</li>
                                    <li className='sub-text'> - Strong problem-solving abilities and adaptability to new technologies and challenges. </li>
                                </ul>
                            </li><br/>

                        </ol>
                        
                        <div style={{ height: 50 }}></div>
                        <SoftwareSkill />
                    </div>
                    

                   
                </Col>

                <Col span={12} className="right-ido">
                    <div>
                        {
                            idoSkills?.map((skill, index) => (
                                <Row className='right-ido-items' style={{ 
                                    borderLeft: index % 2 == 0 ? "6px solid "+skill?.color_code : 0,
                                    borderRight: index % 2 == 1 ? "6px solid "+skill?.color_code : 0 
                                }} data-aos="fade-up">
                                    <Col span={4}>
                                        <div className='icon-container' style={{ background: skill?.color_code }}>
                                            <i className={skill?.icon}></i>
                                        </div>
                                    </Col>
                                    <Col span={16} style={{ paddingLeft: "20px" }}>
                                        <div className='bottom-i-do-number' style={{ fontSize: "18px",color: skill?.color_code }}>{skill?.title}</div>
                                        <div className='bottom-i-do-txt' style={{ fontSize: "14px", color: "#aaa" }}>{skill?.total_proj}+ Projects </div>
                                    </Col>
                                </Row>
                            ))
                        }


                        <Row className='bottom-i-do'>
                            <Col span={10} className='bottom-i-do-left' data-aos="fade-left">
                                <div className='bottom-i-do-number'>19 +</div>
                                <div className='bottom-i-do-txt'>Project Completed</div>
                            </Col>
                            <Col span={8} className='bottom-i-do-right' data-aos="fade-right">
                                <div className='bottom-i-do-number'>5 +</div>
                                <div className='bottom-i-do-txt'>Years Completed</div>
                            </Col>
                        </Row>
                    </div>
                    
                </Col>
            </Row>
        )
    }
}

export default Ido;