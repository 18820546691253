import {React, Component } from 'react';
import { Breadcrumb, Divider, Layout, Menu, Tag } from 'antd';
import { Col, Row } from 'antd';

import "./../../css/mobile/MyReviewsMobile.css";
import { reviews, skills } from '../../data/Content';

class MyReviewsMobile extends Component{

  constructor(props){
      super(props)
  }
  
  render(){
    return(
      <div style={{marginTop:26}}>
        <div className='m-container-title' data-aos="fade-down">
          <span>Peoples Talk`s About Me</span>
        </div>
        <div className='m-my-reviews-container' id="review">

          <Row style={{ justifyContent: "center", alignItems: "center" }}>
            {
              reviews.map((item, index) => (
                <div className='m-my-reviews-items' 
                  onClick={()=>{window.open(item?.linkedIn, "_blank")}}
                  style={{ borderLeft: "6px solid " + item?.color, borderRight: "6px solid " + item?.color }} data-aos="fade-up">
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 30 }}>
                    <div className='m-my-reviews-picture' style={{ background: "#009ED0" }}>
                      <img src={item?.image} style={{ width: 70, heigh: 70, borderRadius: 50 }} />
                    </div>
                  </div>
                  <div className='m-my-reviews-text' >{item?.review}</div>
                  <Divider />
                  <div className='m-my-reviews-name' style={{ color: item?.color, marginBottom: 5 }}>{item?.name}</div>
                  <div className='m-my-reviews-position'>{item?.role}</div>
                  <div className='m-my-reviews-at'>at</div>
                  <div className='m-my-reviews-company'>{item?.company} </div>
                </div>
              ))}
          </Row>
        </div>
      </div>
    );
}}

export default MyReviewsMobile;